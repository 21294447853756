<template>
  <div>
    <v-img
      contain
      :width="width"
      :height="height"
      :src="source"
      :lazy-src="source"
      :style="customStyle"
      :class="edges"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'Photo',
  props: {
    source: String,
    edges: String,
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    customStyle: String
  }
}
</script>
